<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="handleWarningConfirmed"
    />

    <v-dialog
      v-model="showCreateDoctorForm"
      @keydown.esc="showCreateDoctorForm = false"
      persistent
      width="1200"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showCreateDoctorForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <CreateDoctor
          :showCancel="true"
          :title="formTitle"
          @created="handleCreated"
          @on-cancel="showCreateDoctorForm = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showEditDoctorForm"
      @keydown.esc="showEditDoctorForm = false"
      persistent
      width="1200"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showEditDoctorForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <DoctorForm ref="DoctorFormRef" @updated="handleUpdated" />
      </v-card>
    </v-dialog>

    <div class="d-md-flex align-center">
      <div class="mr-5">
        <SearchInput @search="handleSearchInput" />
      </div>
      <div class="mb-6">
        <v-btn
          title="Crear nuevo"
          color="primary"
          small
          depressed
          @click="handleClickNew"
        >
          nuevo
        </v-btn>
      </div>
    </div>

    <p class="caption pasive--text text-right">{{ count }} doctores</p>

    <v-data-table
      :headers="headers ? headers : defaultHeaders"
      :items="items"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="loading"
      loading-text="Cargando..."
      :no-data-text="
        filter === ''
          ? 'No hay pacientes registrados'
          : 'Paciente no encontrado'
      "
      item-key="id"
      item-class="text-center"
      :class="'row-pointer'"
      disable-sort
      :footer-props="{
        itemsPerPageText: 'Resultados por pág.',
        itemsPerPageAllText: 'Todos',
        pageText: '',
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 60],
      }"
      :options.sync="options"
      :server-items-length="count"
      @click:row="clickDoctor"
    >
      <template slot="item.name" scope="props">
        <div>
          {{ props.item.user.firstName }} {{ props.item.user.lastName }}
        </div>
      </template>
      <template slot="item.lastLogin" scope="props">
        <div>
          {{
            props.item.user.lastLogin
              ? formatDateAndTime(props.item.user.lastLogin)
              : "-"
          }}
        </div>
      </template>
      <template slot="item.isActive" scope="props">
        <v-chip
          :color="props.item.user.isActive ? 'primary' : 'grey-9'"
          class="text-weight-bold"
          small
          dark
        >
          {{ props.item.user.isActive ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
      <template slot="item.isBanned" scope="props">
        {{ props.item.user.isBanned ? "Banneado" : "Habilitado" }}
        <v-icon small>{{
          props.item.user.isBanned ? "mdi-close" : "mdi-check"
        }}</v-icon>
      </template>
      <template slot="item.subscription" scope="props">
        <SubscriptionChip :subscription="props.item.subscription" />
      </template>
      <template slot="item.action" scope="props">
        <div class="d-flex justify-center">
          <v-menu bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text small icon>
                <v-icon size="20px">mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click.stop="handleClickEdit(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"
                      >mdi-square-edit-outline</v-icon
                    >
                    <div class="subtitle-2 font-weight-regular">Modificar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click.stop="toggleActive(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3">
                      mdi-account-reactivate
                    </v-icon>
                    <div class="subtitle-2 font-weight-regular">
                      {{ props.item.user.isActive ? "Desactivar" : "Activar" }}
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click.stop="toggleBanned(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"> mdi-lock </v-icon>
                    <div class="subtitle-2 font-weight-regular">
                      {{
                        props.item.user.isBanned
                          ? "Habilitar"
                          : "Deshabilitar"
                      }}
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import WarningDialog from "../WarningDialog.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from "axios";
import SearchInput from "../SearchInput.vue";
import moment from "moment";
import CreateDoctor from "./CreateDoctor.vue";
import DoctorForm from "../Profile/UpdateProfile/DoctorForm.vue";
import { props } from "@chenfengyuan/vue-countdown";
import SubscriptionChip from "./SubscriptionChip.vue";

export default {
  name: "DoctorList",
  props: ["clickable", "headers", "showDoctorDialog", "height"],
  components: {
    DoctorForm,
    CreateDoctor,
    WarningDialog,
    SearchInput,
    SubscriptionChip,
  },
  data() {
    return {
      showCreateDoctorForm: false,
      items: [],
      expanded: [],
      singleExpand: false,
      defaultHeaders: [
        { text: "Nombre", value: "name" },
        { text: "Teléfono", value: "user.phone" },
        { text: "Correo electrónico", value: "user.email" },
        { text: "Subscripción", value: "subscription", align: "center" },
        { text: "Acceso", value: "isBanned" },
        { text: "Último login", value: "lastLogin" },
        { text: "Estado", value: "isActive", align: "center" },
        { text: "Acciones", value: "action", align: "center" },
      ],
      filter: "",
      loading: false,
      deleting: false,
      showDoctorForm: false,
      showEditDoctorForm: false,
      formTitle: "Crear",
      item: null,
      term: "",
      page: 1,
      pageSize: 0,
      options: {},
      count: 0,
      warningAction: "delete",
    };
  },
  computed: {
    ...mapGetters(["doctors", "currentUser", "doctorForms"]),
  },
  methods: {
    ...mapActions(["fetchDoctors"]),
    ...mapMutations(["setAlert", "removeDoctor","updateDoctor"]),

    formatDateAndTime(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },

    handleClickEdit(item) {
      this.item = item;
      this.formTitle = "Actualizar";
      this.showEditDoctorForm = true;
      this.$nextTick(() => {
        this.$refs.DoctorFormRef.item = this.item;
      });
    },

    handleClickNew() {
      this.showCreateDoctorForm = true;
    },

    handleCreated() {
      this.showCreateDoctorForm = false;
      this.getData();
    },

    handleUpdated() {
      this.showEditDoctorForm = false;
      this.getData();
    },

    clickDoctor(row) {
      this.$router.push("/doctor/" + row.id);
    },

    toggleActive(item) {
      this.warningAction = "delete";
      this.item = item;
      this.$refs.WarningDialogRef.btnText = this.item.user.isActive
        ? "desactivar"
        : "activar";
      this.$refs.WarningDialogRef.description = `¿Seguro que desear ${
        this.item.user.isActive ? "desactivar" : "activar"
      } a ${this.item.user.firstName} ${this.item.user.lastName}?`;
      this.$refs.WarningDialogRef.show = true;
    },

    toggleBanned(item) {
      this.warningAction = "ban";
      this.item = item;
      this.$refs.WarningDialogRef.btnText = this.item.user.isBanned
        ? "desactivar"
        : "activar";
      this.$refs.WarningDialogRef.description = `¿Seguro que desear ${
        this.item.user.isBanned
          ? "deshabilitar el acceso al sistema"
          : " habilitar el acceso al sistema"
      } a ${this.item.user.firstName} ${this.item.user.lastName}?`;
      this.$refs.WarningDialogRef.show = true;
    },

    handleWarningConfirmed() {
      if (this.warningAction == "delete") {
        this.deleteDoctor();
      }

      if (this.warningAction == "ban") {
        this.banDoctor();
      }
    },

    async deleteDoctor() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/doctor/${this.item.id}`, {
          data: {
            doctor: this.item,
          },
        });
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.warning = false;
        this.item.user.isActive = !this.item.user.isActive;
        this.updateDoctor(this.item);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },

    async banDoctor() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/doctor/${this.item.id}/banned`, {
          data: {
            doctor: this.item,
          },
        });
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.warning = false;
        this.item.user.isBanned = !this.item.user.isBanned;
        this.updateDoctor(this.item);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },

    handleSearchInput(term) {
      this.term = term;
      this.getData({ term });
    },

    async getData(params) {
      this.loading = true;

      this.page = params?.page || 1;
      this.pageSize = params?.pageSize || 10;

      const data = await this.fetchDoctors({
        term: this.term,
        page: this.page,
        pageSize: this.pageSize,
      });

      this.items = data?.items || [];
      this.count = data?.count || 0;

      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler(value) {
        this.getData({
          page: value.page || 1,
          pageSize: value.itemsPerPage || 15,
        });
      },
      deep: true,
    },
    doctors: {
      handler(value) {
        this.items = value || [];
      },
    },
  },
};
</script>
