<template>
  <div>
    <v-chip small v-if="subscription == 'standard'" color="success">
      Standard
    </v-chip>
    <v-chip small v-if="subscription == 'pro'" color="primary">
      Pro
    </v-chip>
    <v-chip small v-if="subscription == 'premium'" color="black" dark>
      <h4 class="premium-text">Premium</h4>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "SubscriptionChip",
  props: ["subscription"],
};
</script>

<style scoped>
.premium-text {
  background: linear-gradient(to right, #76b2fc, #66fdf8);
  -webkit-background-clip: text;
  color: transparent;
}
</style>
