<template>
  <v-form @submit.prevent="editDoctor()" v-model="validation" ref="form">
    <v-container>
      <Title :title="'Modificar datos'" class="mb-8" />
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.user.firstName"
            type="text"
            label="Nombres"
            class="my-1"
            :rules="rules.default"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.user.lastName"
            type="text"
            label="Apellidos"
            class="my-1"
            :rules="rules.default"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="form.gender"
            :items="genders"
            label="Sexo biológico"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.user.phone"
            label="Teléfono"
            type="text"
            class="my-1"
            :rules="rules.phone"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.user.email"
            type="e-mail"
            label="Correo electrónico"
            class="my-1"
            :readonly="true"
            :rules="rules.email"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.exequatur"
            type="number"
            label="Exequatur"
            class="my-1"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-select
            v-model="form.subscription"
            :items="subscriptions"
            :readonly="currentUser.role !== 'admin'"
            label="Subscripción"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="12">
          <v-combobox
            v-model="form.specialtiesByDoctor"
            :items="specialties"
            :rules="rules.select"
            chips
            label="Especialidades"
            multiple
            item-text="description"
            outlined
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                color="dark_primary"
                class="white--text"
                close
                small
                @click="select"
                @click:close="remove(item)"
              >
                <strong>{{ item.description }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-combobox
            v-model="form.arsByDoctor"
            :items="arss"
            label="Aseguradoras aceptadas"
            multiple
            chips
            item-text="description"
            outlined
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                color="dark_primary"
                class="white--text"
                close
                small
                @click="select"
                @click:close="removeArs(item)"
              >
                <strong>{{ item.description }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="text--right">
      <v-btn
        small
        color="primary"
        class="pa-5"
        type="submit"
        depressed
        :disabled="!validation"
        :loading="loading"
      >
        Actualizar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import axios from "axios";
import Title from "../../Title.vue";
import { subscriptions } from "../../../const/doctor.const";

export default {
  name: "DoctorForm",
  components: {
    Title,
  },
  data() {
    return {
      subscriptions: subscriptions,
      form: {
        // doctor
        id: "",
        user: {
          id: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
        },
        gender: "",
        exequatur: "",
        subscription: "",
        specialtiesByDoctor: [],
        arsByDoctor: [],
      },
      item: null,
      genders: [
        { text: "Masculino", value: "M" },
        { text: "Femenino", value: "F" },
        { text: "Sin definir", value: "" },
      ],
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres",
        ],
        email: [(v) => /.+@.+\..+/.test(v) || "No es un correo valido"],
        phone: [(v) => !!v || "Campo obligatorio"],
      },
      loading: false,
      validation: true,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "specialties", "arss"]),
  },
  methods: {
    ...mapMutations(["setAlert", "setCurrentUser"]),
    ...mapActions(["fetchSpecialties", "fetchArs"]),
    remove(item) {
      this.form.specialtiesByDoctor.splice(
        this.form.specialtiesByDoctor.indexOf(item),
        1
      );
      this.form.specialtiesByDoctor = [...this.form.specialtiesByDoctor];
    },
    removeArs(item) {
      this.form.arsByDoctor.splice(this.form.arsByDoctor.indexOf(item), 1);
      this.form.arsByDoctor = [...this.form.arsByDoctor];
    },
    async editDoctor() {
      try {
        this.loading = true;
        const doctorToEdit = {
          ...this.form,
          specialties: this.form.specialtiesByDoctor,
          ars: this.form.arsByDoctor,
        };
        const res = await axios.put(
          `/api/doctor/${this.form.id}`,
          doctorToEdit
        );

        this.$emit("updated", { item: res.data.doctor });

        const alert = {
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          text: "Datos actualizados correctamente",
        };
        this.setAlert(alert);
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      }
      this.loading = false;
    },
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.form.id = newVal?.id;
        this.form.gender = newVal?.gender;
        this.form.specialtiesByDoctor = newVal?.specialties;
        this.form.arsByDoctor = newVal?.ars;
        this.form.exequatur = newVal?.exequatur || "";
        this.form.subscription = newVal?.subscription || "standard";

        this.form.user.id = newVal?.user?.id;
        this.form.user.firstName = newVal?.user?.firstName;
        this.form.user.lastName = newVal?.user?.lastName;
        this.form.user.phone = newVal?.user?.phone;
        this.form.user.email = newVal?.user?.email;
      },
    },
  },
};
</script>
