<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Doctores</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <DoctorList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DoctorList from "../components/Doctor/DoctorList.vue";

export default {
  name: "Doctors",
  components: {
    DoctorList,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
