<template>
  <div>
    <v-form @submit.prevent="createDoctor()" v-model="validation" ref="form">
      <h3 class="text-left mb-5">Crear doctor</h3>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="firstName"
              type="text"
              label="Nombres"
              class="my-1"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastName"
              type="text"
              label="Apellidos"
              class="my-1"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="gender"
              :items="genders"
              label="Sexo biológico"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="phone"
              type="number"
              label="Teléfono"
              class="my-1"
              @wheel="$event.target.blur()"
              :rules="rules.phone"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="email"
              type="e-mail"
              label="Correo electrónico"
              class="my-1"
              :rules="rules.email"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="password"
              label="Contraseña"
              class="my-1"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              :rules="rules.password"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="exequatur"
              type="number"
              label="Exequatur"
              class="my-1"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location.name"
              type="text"
              label="Nombre sede"
              class="my-1"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location.address"
              type="text"
              label="Dirección sede"
              class="my-1"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location.phoneNumber"
              type="text"
              label="Teléfono sede"
              class="my-1"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="subscription"
              :items="subscriptions"
              label="Subscripción"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-combobox
              v-model="specialtiesByDoctor"
              :items="specialties"
              :rules="rules.select"
              label="Especialidades"
              multiple
              item-text="description"
              outlined
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  color="dark_primary"
                  class="white--text"
                  close
                  small
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item.description }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="arsByDoctor"
              :items="arss"
              label="Aseguradoras con las que trabaja"
              multiple
              item-text="description"
              outlined
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  color="dark_primary"
                  class="white--text"
                  close
                  small
                  @click="select"
                  @click:close="removeArs(item)"
                >
                  <strong>{{ item.description }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-container>
      <div class="text--right">
        <v-btn
          small
          color="primary"
          class="pa-5"
          type="submit"
          :disabled="!validation"
          :loading="loading"
        >
          Crear
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
import { subscriptions } from "../../const/doctor.const";

export default {
  name: "CreateDoctor",
  data() {
    return {
      dialog: false,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      gender: "",
      exequatur: "",
      subscription: "standard",
      specialtiesByDoctor: [],
      arsByDoctor: [],
      genders: [
        { text: "Masculino", value: "M" },
        { text: "Femenino", value: "F" },
      ],
      location: {
        name: "",
        address: "",
        phoneNumber: "",
      },
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres",
        ],
        select: [(v) => !!v || "Campo obligatorio"],
        email: [(v) => /.+@.+\..+/.test(v) || "No es un correo valido"],
        password: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length >= 8) || "Debe contener al menos 8 caracteres",
        ],
        phone: [
          (v) => !!v || "Campo obligatorio",
          (v) =>
            (v && v.length == 10) ||
            "Debe contener 10 dígitos (ejemplo: 8093451290)",
        ],
      },
      validation: true,
      loading: false,
      showPass: false,
    };
  },
  computed: {
    ...mapGetters(["specialties", "arss"]),
    subscriptions() {
      return subscriptions;
    },
  },
  methods: {
    ...mapMutations(["setAlert", "pushDoctor"]),
    async createDoctor() {
      try {
        this.loading = true;
        const newDoctor = {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          password: this.password,
          gender: this.gender,
          specialties: this.specialtiesByDoctor,
          ars: this.arsByDoctor,
          exequatur: this.exequatur,
          location: this.location,
          subscription: this.subscription,
        };
        await axios.post("/api/doctor", newDoctor);
        const alert = {
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          text: "Doctor creado correctamente",
        };
        this.setAlert(alert);
        this.$emit("created");
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      } finally {
        this.loading = false;
      }
    },
    remove(item) {
      this.specialtiesByDoctor.splice(
        this.specialtiesByDoctor.indexOf(item),
        1
      );
      this.specialtiesByDoctor = [...this.specialtiesByDoctor];
    },
    removeArs(item) {
      this.arsByDoctor.splice(this.arsByDoctor.indexOf(item), 1);
      this.arsByDoctor = [...this.arsByDoctor];
    },
  },
};
</script>
